import { Menu } from "../menu/Menu";
import { S } from "../HeaderMenu_Styles";


export const DesktopMenu = () => {
  return (
    <S.DesktopMenu>
      <Menu/>
    </S.DesktopMenu>
  );
};

